import {
  INIT_HANDLE_ORDER, INIT_CARD_PAYMENT_ORDER, INIT_INVOICE_PAYMENT_ORDER,
  ORDER_INVOICE_POST_INIT, ORDER_ADD_CARD, ORDER_CHARGE_CARD_FAILURE,
  ORDER_SUBSCRIBE, ORDER_SUBSCRIBE_FAILURE, ORDER_INIT_SUCCESS, ORDER_COMPLETE,
  ORDER_INIT_SUBSCRIBE,
  ORDER_ADD_CARD_FAILURE,
  ORDER_INVOICE_POST_INIT_FAILURE,
  INIT_MOBILEPAY_PAYMENT_ORDER,
  ORDER_MOBILEPAY_REDIRECT,
  ORDER_MOBILEPAY_RETURNED,
} from '../actions/types/order';
import { PaymentMethod } from '../enums';

const orderHandlingReducer = (state: any, action: any) => {
  switch (action.type) {
    case INIT_HANDLE_ORDER:
      switch (action.paymentMethod) {
        case PaymentMethod.CARD:
          return { ...state, type: INIT_CARD_PAYMENT_ORDER };
        case PaymentMethod.INVOICE:
          return { ...state, type: INIT_INVOICE_PAYMENT_ORDER };
        case PaymentMethod.MOBILEPAY:
          return { ...state, type: INIT_MOBILEPAY_PAYMENT_ORDER };
        default:
          throw new Error('Invalid payment mode');
      }
    case INIT_INVOICE_PAYMENT_ORDER:
      return { ...state, type: INIT_INVOICE_PAYMENT_ORDER };
    case ORDER_INVOICE_POST_INIT:
      return { ...state, type: ORDER_INVOICE_POST_INIT };
    case ORDER_INVOICE_POST_INIT_FAILURE:
      return { ...state, type: ORDER_INVOICE_POST_INIT_FAILURE };
    case ORDER_ADD_CARD:
      return { ...state, type: ORDER_ADD_CARD };
    case ORDER_ADD_CARD_FAILURE:
      return { ...state, type: ORDER_ADD_CARD_FAILURE };
    case ORDER_CHARGE_CARD_FAILURE:
      return { ...state, type: ORDER_CHARGE_CARD_FAILURE };
    case ORDER_INIT_SUCCESS:
      return { ...state, type: ORDER_INIT_SUCCESS };
    case ORDER_INIT_SUBSCRIBE:
      return { ...state, type: ORDER_INIT_SUBSCRIBE };
    case ORDER_SUBSCRIBE:
      return { ...state, type: ORDER_SUBSCRIBE };
    case ORDER_SUBSCRIBE_FAILURE:
      return { ...state, type: ORDER_SUBSCRIBE_FAILURE };
    case ORDER_COMPLETE:
      return { ...state, type: ORDER_COMPLETE };
    case ORDER_MOBILEPAY_REDIRECT:
      return { ...state, type: ORDER_MOBILEPAY_REDIRECT };
    case ORDER_MOBILEPAY_RETURNED:
      return { ...state, type: ORDER_MOBILEPAY_RETURNED };
    default:
      throw new Error(`Invalid action ${action.type}`);
  }
};

export default orderHandlingReducer;
